import { useEffect, useState } from "react";
import { Flex, ModalFooter, useToast } from "@chakra-ui/react";
import { Modal } from "../Modal";
import { Loading } from "../../shared/Loading";
import { ButtonCustom } from "../../form/controls/form.button";
import { FormInput } from "../../form/controls/form.input";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { companyUserService } from "../../../service/company/company-user.service";
import { Role } from "../../../models/enum/role.enum";
import { DropdownInput } from "../../form/controls/dropdown.input";

export const AddCompanyUserModal = ({
  companyId,
  isOpen,
  onClose,
  onSuccess,
  onSubmit = undefined,
}) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(Role.STANDARD);
  const [formState, setFormState] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const onRoleChange = (event: any) => {
    let { value } = event.target;
    setRole(value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    await companyUserService
      .create({ ...formState, companyId, roleName: role })
      .then(() => {
        onClose();
        toast(successToast(`${formState.email} has been successfully added!`));
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        const message =
          err.response.data.message ||
          "Something went wrong. Please try again.";
          
        toast(errorToast(message));
      })
      .finally(() => {
        setLoading(false);
        if (onSubmit) onSubmit();
      });
  };

  const handleClose = () => {
    setFormState({
      email: "",
      firstName: "",
      lastName: "",
    });

    onClose();
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Modal isOpen={isOpen} header="Add User" onClose={handleClose}>
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <Flex flexDir="row" gap="12px">
            <FormInput
              name="firstName"
              title="First Name"
              required
              onChange={onInputChange}
            />
            <FormInput
              name="lastName"
              title="Last Name"
              required
              onChange={onInputChange}
            />
          </Flex>

          <FormInput
            name="email"
            title="User Email"
            required
            type="email"
            placeholder="Enter user email address"
            onChange={onInputChange}
          />
          <DropdownInput
            name="role"
            title="Role"
            defaultValue={"STANDARD"}
            options={Role}
            onChange={onRoleChange}
          />

          <Flex justifyContent="flex-end" gap="6px" mt="36px">
            <ButtonCustom secondary neutral onClick={onClose}>
              Cancel
            </ButtonCustom>
            <ButtonCustom type="submit">Add User</ButtonCustom>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
