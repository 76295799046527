import { Flex } from "@chakra-ui/react";

export const FlexCol = ({
  between = undefined,
  center = undefined,
  children,
  ...props
}) => {
  if (center && between) {
    throw new Error("FlexCol: Cannot use both 'center' and 'between' props simultaneously");
  }

  const justifyContent = center ? "center" : between ? "space-between" : undefined;

  return (
    <Flex
      h="full"
      w="full"
      justifyContent={justifyContent}
      alignItems={center ? "center" : undefined}
      flexDir="column"
      gap="4px"
      {...props}
    >
      {children}
    </Flex>
  );
};
