import { Flex, Box, Image, Badge, Tooltip, useToast } from "@chakra-ui/react";
import { CardTitle } from "../../../shared/CardTitle";
import CancelIcon from "@mui/icons-material/Cancel";
import { SendIcon } from "../../../shared/icons/Icons";
import AlertDialog from "../../../shared/AlertDialog";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  errorToast,
  successToast,
} from "../../../../constants/toast.constants";
import { companyUserService } from "../../../../service/company/company-user.service";
import { ButtonCustom } from "../../../form/controls/form.button";
import { AddCompanyUserModal } from "../add-user.modal";

export const CompanyDetailUserTable = ({ users, onSuccess, companyId }) => {
  const [deleteUserAlertOpen, setDeleteUserAlertOpen] = useState({
    id: null,
    open: false,
  });
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);

  const toast = useToast();
  const profile = useSelector((state: RootState) => state.userProfile);
  const { isManager } = profile;

  const deleteFromCompany = async () => {
    await companyUserService
      .delete(deleteUserAlertOpen.id)
      .then(() => {
        toast(successToast("User deleted successfully"));
        onSuccess();
      })
      .catch(() => {
        toast(errorToast("Something went wrong. Try again."));
      });
  };

  const deactivateUser = async () => {
    await companyUserService
      .deactivate({ id: deleteUserAlertOpen.id, companyId })
      .then(() => {
        toast(successToast("User deactivated successfully"));
        onSuccess();
      })
      .catch(() => {
        toast(errorToast("Something went wrong. Try again."));
      });
  };

  const activateUser = async (id: string) => {
    await companyUserService
      .activate({ id, companyId })
      .then(() => {
        toast(successToast("User activated successfully"));
        onSuccess();
      })
      .catch(() => {
        toast(errorToast("Something went wrong. Try again."));
      });
  };


  return (
    <>
      <Flex
        bg="white"
        boxShadow="base"
        borderRadius="md"
        overflowY="auto"
        flexDir="row"
        justifyContent="space-between"
        width="100%"
      >
        <Flex w="100%" minH="250px" flexDirection="column">
          <Flex
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
            p={4}
          >
            <CardTitle>Users ({users?.length ?? 0})</CardTitle>
            <ButtonCustom neutral onClick={() => setAddUserModalOpen(true)}>
              + Add User
            </ButtonCustom>
          </Flex>
          {users
            ?.sort((a, b) => a.deleted - b.deleted)
            .map((row) => (
              <Flex
                flexDir="row"
                p={4}
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex flexDir="row" gap="4px" alignItems="center">
                  {row.deleted ? (
                    <CancelIcon
                      style={{ fontSize: "16px", color: "#A0AEBF" }}
                    />
                  ) : (
                    <Image
                      src="/assets/check.svg"
                      alt="Zolo Logo"
                      width="16px"
                      height="16px"
                      mt="2px"
                    />
                  )}
                  <Badge
                    colorScheme={row.role === "admin" ? "green" : "gray"}
                    w="fit-content"
                  >
                    {row.role === "admin" ? "ADMIN" : "STANDARD"}
                  </Badge>
                  <Box color={row.deleted ? "gray.500" : "black"}>
                    {row.email}
                  </Box>
                </Flex>

                {isManager && (
                  <Flex flexDir="row" gap="12px" alignItems="center">
                    <Tooltip label="Move Companies" aria-label="edit">
                      <Box
                        cursor="pointer"
                        onClick={() => alert("Moving users coming soon")}
                      >
                        <SendIcon />
                      </Box>
                    </Tooltip>

                    <Tooltip label={row.deleted ? "Activate" : "Delete"} aria-label="edit">
                      <Box cursor="pointer">
                        <Image
                          src={row.deleted ? "/assets/check-blue.svg" : "/assets/trash-red.svg"}
                          alt="Visibility"
                          width={row.deleted ? "18px" : "24px"}
                          mr={row.deleted ? "4px" : "0"}
                          _hover={{
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (row.deleted) {
                              activateUser(row.id);
                            } else {
                              setDeleteUserAlertOpen({ id: row.id, open: true });
                            }
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
            ))}
        </Flex>
      </Flex>

      <AddCompanyUserModal
        companyId={companyId}
        isOpen={addUserModalOpen}
        onClose={() => setAddUserModalOpen(false)}
        onSuccess={() => {
          setAddUserModalOpen(false);
          toast(successToast("User added successfully"));
          onSuccess();
        }}
      />

      <AlertDialog
        isOpen={deleteUserAlertOpen.id}
        onClose={() => setDeleteUserAlertOpen({ id: null, open: false })}
        title="Danger ahead"
        colorScheme="red"
        confirmText="I understand, delete user"
        onConfirm={() => {
          setDeleteUserAlertOpen({ id: null, open: false });
          deleteFromCompany();
        }}
      >
        <Box>
          You can't undo this action. <br /> The user will be permanently
          deleted, and so will any associated records.
        </Box>
        <Box
          my="16px"
          cursor="pointer"
          textDecoration="underline"
          color="red.500"
          onClick={() => {
            setDeleteUserAlertOpen({ id: null, open: false });
            deactivateUser();
          }}
        >
          Click here to deactivate instead.
        </Box>
      </AlertDialog>
    </>
  );
};
