import { Box, Center, Flex, Link, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Image } from "@chakra-ui/react";
import { IdentityService } from "../../service/identity/identity.service";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../store/slice/Account/user-profile.slice";
import AuthLayout from "../../components/layout/basic.layout";
import { useNavigate } from "react-router-dom";
import { MagiclinkService } from "../../service/magiclink/magiclink.service";
import { CompanyService } from "../../service/company/company.service";
import { RootState, destroyAllState } from "../../store";
import BaseContainer from "../../components/form/base.container";
import { FormInput } from "../../components/form/controls/form.input";
import { ButtonCustom } from "../../components/form/controls/form.button";
import { LoadingComponent } from "../../components/shared/Loading";

const Login: React.FC<any> = (props: any) => {
  const magiclinkService = new MagiclinkService();
  const identityService = new IdentityService();
  const companyService = new CompanyService();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sessionToken = useSelector(
    (state: RootState) => state.token.accessToken
  );

  const [formState, setFormState] = useState({ email: "" });
  const [loading, setLoading] = useState(false);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const init = async () => {
    if (!!sessionToken?.length) {
      navigate("/app/dashboard");
      return;
    }

    await magiclinkService.logout();
    destroyAllState();
  };

  useEffect(() => {
    init();
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);
    await magiclinkService
      .handleLoginWithEmail(formState.email)
      .then(() => {
        // Open empty page and close
        window.open("about:blank", "_self");
        window.close();
      })
      .catch((err) => {
        alert(err);
        return;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthLayout email={formState.email} isLogin isAuthenticating={loading}>
      <Flex
        flexDirection="column"
        gap="16px"
        width="360px"
        alignItems="center"
        zIndex={10}
      >
        <Text fontSize="46px" fontWeight="700" color="brand.yellow">
          Admin Portal.
        </Text>
        <BaseContainer styles={{ marginBottom: 0, width: "100%" }}>
          <Center mb="40px">
            <Image src="/logo.svg" alt="ZOLO Logo" width="180px" />
          </Center>

          <form onSubmit={onSubmit}>
            <Flex flexDirection="column" gap="16px">
              <FormInput
                name="email"
                title="Email"
                required
                type="email"
                placeholder="Enter your email address"
                onChange={onInputChange}
              />
              <ButtonCustom
                type="submit"
                disabled={loading || !formState.email}
                loading={loading}
              >
                Login to ZOLO
              </ButtonCustom>
            </Flex>
          </form>
        </BaseContainer>
      </Flex>
    </AuthLayout>
  );
};

export default Login;
