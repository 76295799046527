import { Role } from "../../models/enum/role.enum";
import { UserModel } from "../../models/user.model";
import { BaseService } from "../base.service";

export class CompanyUserService extends BaseService {
  constructor() {
    super();
  }

  async getUser(id: string): Promise<any> {
    const resp = await this.client.get(`/super/company/user/get?id=${id}`);
    return resp.data;
  }

  async getUsers(companyId: string): Promise<any[]> {
    const resp = await this.client.post(`/super/company/user/list`, {
      filter: {
        companyId,
      },
    });

    return resp.data;
  }

  async create(user: {
    email: string;
    companyId: string;
    firstName: string;
    lastName: string;
    roleName: Role;
  }): Promise<{ id: string }> {
    const resp = await this.client.post(`/super/company/user/create`, user);
    return resp.data;
  }

  async delete(id: string): Promise<any[]> {
    const resp = await this.client.post(`/super/company/user/delete`, {
      id,
    });
    
    return resp.data;
  }

  async deactivate(user: { id: string; companyId: string }): Promise<any[]> {
    const resp = await this.client.post(`/super/company/user/deactivate`, user);
    return resp.data;
  }

  async activate(user: { id: string; companyId: string }): Promise<any[]> {
    const resp = await this.client.post(`/super/company/user/activate`, user);
    return resp.data;
  }
}

export const companyUserService = new CompanyUserService();
