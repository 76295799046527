import { Box, Text } from "@chakra-ui/react";

export const FormTitle = ({
  required,
  children,
  ...props
}: {
  required?: boolean;
  children: React.ReactNode;
  [key: string]: any;
}) => {
  return (
    <Box fontWeight={600} fontSize={"14px"} {...props}>
      {children}
      {required ? (
        <Box
          as={"span"}
          fontWeight="700"
          fontSize="14px"
          color="#792551"
          ml="3px"
        >
          *
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
