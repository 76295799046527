import { UserProfile } from "../../models/user-profile.model";
import { UserModel } from "../../models/user.model";
import { BaseService } from "../base.service";

export class UserService extends BaseService {
  constructor() {
    super();
  }

  async get(): Promise<UserModel> {
    const resp = await this.client.get(`/user`);
    return resp.data;
  }

  async update(data: any): Promise<any> {
    const resp = await this.client.get(`/update`, data);
    return resp.data;
  }

  async getUsers(): Promise<any[]> {
    const resp = await this.client.get(`/super/user/list`);
    return resp.data;
  }

  async verifyRegistrationToken(email: string, token: string) {
    await this.client.post(`/user/token/verify`, { email, token });
  }

  async deactivate(user: { email }): Promise<any[]> {
    const resp = await this.client.post(`/super/user/manage/deactivate`, user);
    return resp.data;
  }

  async activate(user: { email }): Promise<any[]> {
    const resp = await this.client.post(`/super/user/manage/activate`, user);
    return resp.data;
  }

  async invite(user: { email }): Promise<UserModel> {
    const resp = await this.client.post(`/super/user/manage/invite`, user);
    return resp.data;
  }

  async getUserDashboard(): Promise<{ count: number; totalSales: number }> {
    const resp = await this.client.get(`/super/user/dashboard`);
    return resp.data;
  }

  async updateRole(user: {
    isManager: boolean;
    email: string;
  }): Promise<any[]> {
    const resp = await this.client.put(`/super/user/update/role`, user);
    return resp.data;
  }

  async updateProfilePhoto(formData: any) {
    const resp = await this.client.post(
      `/super/user/media/profile/photo`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return resp.data;
  }
}

export const userService = new UserService();