import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const StatusTag: React.FC<{
  children: any;
  type: "success" | "warning" | "error" | "info" | null;
  style?: any;
  hidden?: boolean;
  excludeDot?: boolean;
}> = (props: any) => {
  const { type, children, style, excludeDot } = props;

  const [indicatorProps, setIndicatorProps] = useState({} as any);

  const indicatorColor = () => {
    if (type === "info") {
      setIndicatorProps({
        indicator: "white",
        text: "white",
        bg: "#0176d3",
      });
    }

    if (type === "success") {
      setIndicatorProps({
        indicator: "#56CB62",
        text: "#166534",
        bg: "#DCFCE7",
      });
    }


    if (type === "error") {
      setIndicatorProps({
        indicator: "#FEE2E2",
        text: "#FEE2E2",
        bg: "#B91C1C",
      });
    }

    if (type === "warning") {
      setIndicatorProps({
        indicator: "#7b341e",
        text: "#7b341e",
        bg: "#feebc8",
      });
    }

    if (!type) {
      setIndicatorProps({
        indicator: "#3f3e42",
        text: "#3f3e42",
        bg: "#929099",
      });
    }
  };

  useEffect(() => {
    indicatorColor();
  }, [type]);

  return (
    <Box
      pb="5px"
      hidden={props.hidden}
      color={indicatorProps.text}
      bg={indicatorProps.bg}
      minWidth="80px"
      borderRadius="30px"
      fontSize="12px"
      px={2}
      py={1}
    >
      <Flex flexDirection="row" alignItems={"center"} gap={1} fontWeight="semibold">
        {!excludeDot && (
          <Box
            {...style}
            fontSize="18px"
            bg={indicatorProps.indicator}
            w="8px"
            h="8px"
            borderRadius="50%"
          ></Box>
        )}
        {!children ? "Pending" : children}
      </Flex>
    </Box>
  );
};

