import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FormTitle } from "../form/form.title";

export const ToggleSwitch = ({
  title = undefined,
  value = false,
  defaultChecked = undefined,
  onChange = (value: boolean) => {},
  labels = {
    left: "Off",
    right: "On",
  },
  ...props
}) => {
  const [isActive, setIsActive] = useState(!!defaultChecked);

  const handleToggle = () => {
    const newValue = !isActive;
    setIsActive(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setIsActive(!!defaultChecked);
  }, [defaultChecked]);

  return (
    <Box>
      {title && <FormTitle mb={2}>{title}</FormTitle>}
      <Flex
        h="30px"
        w="130px"
        fontSize="14px"
        borderRadius="25px"
        bg="gray.300"
        align="center"
        justify="space-between"
        p="3px"
        cursor="pointer"
        position="relative"
        onClick={handleToggle}
        {...props}
      >
        {/* Background Overlay */}
        <Box
          position="absolute"
          top="3px"
          bottom="3px"
          left={isActive ? "calc(50% + 3px)" : "3px"}
          right={isActive ? "3px" : "calc(50% + 3px)"}
          bg="white"
          borderRadius="22px"
          transition="all 0.3s ease"
          zIndex={1}
        />

        {/* Option: Off */}
        <Box
          flex="1"
          textAlign="center"
          zIndex={2}
          color={isActive ? "gray.500" : "black"}
          fontWeight="600"
          transition="color 0.3s ease"
        >
          {labels.left}
        </Box>

        {/* Option: On */}
        <Box
          flex="1"
          textAlign="center"
          zIndex={2}
          color={isActive ? "black" : "gray.500"}
          fontWeight="600"
          transition="color 0.3s ease"
        >
          {labels.right}
        </Box>
      </Flex>
    </Box>
  );
};
