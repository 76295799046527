import { BaseService } from "../base.service";

export class OpenaiService extends BaseService {
  constructor() {
    super();
  }

  async getInventoryWeight(jobId: string) {
    const resp = await this.client.post("/super/inventory/bulk/weight", {
      jobId,
    });

    return resp?.data;
  }
}

export const openaiService = new OpenaiService();
