import { Box, Center, Flex, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const Loading: React.FC<Props> = ({ loading, fill, ignoreDelay }) => {
  const [showLoading, setShowLoading] = useState(false);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const init = async () => {
    if (!ignoreDelay) {
      await delay(700);
    }

    setShowLoading(true);
  };

  useEffect(() => {
    init();
  }, [loading]);

  useEffect(() => {
    if (!loading) setShowLoading(false);
  }, [loading]);

  if (loading && showLoading) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        position="fixed"
        top="0"
        left={fill ? "0" : "240px"}
        w={fill ? "100vw" : "calc(100vw - 240px)"}
        h="100%"
        zIndex={99}
        opacity="0.9"
        bg="gray"
      >
        <LoadingComponent />
      </Flex>
    );
  }

  return <></>;
};

export const LoadingComponent = ({ color = "brand.yellow", ...props }) => {
  return (
    <Spinner thickness="4px" speed="0.65s" color={color} size="xl" {...props} />
  );
};

interface Props {
  loading: boolean;
  fill?: boolean;
  ignoreDelay?: boolean;
}
