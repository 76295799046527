import { Flex } from "@chakra-ui/react";

export const FlexRow = ({
  between = undefined,
  link = undefined,
  children,
  ...props
}) => {
  return (
    <Flex
      justifyContent={between ? "space-between" : undefined}
      flexDir="row"
      gap="4px"
      alignItems="center"
      _hover={link ? { cursor: "pointer", textDecoration: "underline" } : {}}
      {...props}
    >
      {children}
    </Flex>
  );
};
