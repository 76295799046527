import { useEffect, useRef, useState } from "react";
import { Box, Flex, Text, Badge, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../../components/form/controls/form.button";
import { JobService } from "../../../service/job/job.service";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { InventoryService } from "../../../service/inventory/inventory.service";
import { downloadCsvToMachine } from "../../../util/file.util";
import { EditJobModal } from "../../../components/app/job/edit-job.modal";
import { InventoryTable } from "../../../components/app/inventory/inventory.table";
import { UploadInventoryModal } from "../../../components/app/inventory/upload-inventory.modal";
import { AddInventoryModal } from "../../../components/app/inventory/add-inventory.modal";
import { InventoryModel } from "../../../models/inventory.model";
import { InventoryBulkFilter } from "../../../models/filter/inventory-bulk.filter";
import { JobDetailHeader } from "../../../components/app/job/job-detail.header";
import { useAppDispatch, useAppSelector } from "../../../util/hooks.util";
import { updateJobFilter as inventoryUpdate } from "../../../store/slice/Account/inventory.slice";
import { DownloadIcon } from "@chakra-ui/icons";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddIcon from "@mui/icons-material/Add";
import { TableFooter } from "../../../components/shared/table/TableFooter";
import { JobStatus } from "../../../models/enum/job-status.enum";
import { JobDetailContent } from "../../../components/app/job/job-detail-content.header";
import { Loading } from "../../../components/shared/Loading";
import { openaiService } from "../../../service/integrations/openai-service.service";

export const JobDetail = ({}) => {
  const jobService = new JobService();
  const inventoryService = new InventoryService();
  const dispatch = useAppDispatch();
  const { jobFilter: inventoryFilter, jobSortColumn: inventorySort } =
    useAppSelector((state) => state.inventory);

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [uploadSheetState, setUploadSheetState] = useState({
    open: false,
    isEdit: false,
  });

  const [job, setJob] = useState({} as any);
  const [inventory, setInventory] = useState({
    data: [],
    totalItems: 0,
    totalPage: 0,
  } as { data: InventoryModel[]; totalPage: number; totalItems: number });

  const [searchParams] = useSearchParams();
  const [pageNum, setPageNum] = useState(1);
  const [checkedIds, setCheckedIds] = useState([] as string[]);
  const [refreshKey, setRefreshKey] = useState(0);

  const [activeInventory, setActiveInventory] = useState(null);
  const [globalSearchTerm, setGlobalSearchTerm] = useState("");
  const [searchParamReady, setSearchParamReady] = useState(false);

  useEffect(() => {
    if (searchParams.get("inventory_id")) {
      setActiveInventory(searchParams.get("inventory_id"));
    }
    if (searchParams.get("searchTerm")) {
      setGlobalSearchTerm(searchParams.get("searchTerm"));
    }

    setSearchParamReady(true);
  }, [searchParams]);

  const fetchJob = async () => {
    const prms = new Promise((resolve, reject) =>
      jobService.get(id).then((job) => {
        setJob(job);
        resolve(job);
      })
    );

    return await prms;
  };

  const fetchInventory = async (page?: number, sort?: any) => {
    const PAGE_SIZE = 48;

    const filterPayload = inventorySort
      ? { ...inventorySort, searchTerm: globalSearchTerm }
      : { searchTerm: globalSearchTerm };

    await inventoryService
      .getList(
        {
          jobId: id,
          ...filterPayload,
          ids: activeInventory ? [activeInventory] : undefined,
        },
        undefined,
        page,
        PAGE_SIZE,
        sort ?? inventorySort
      )
      .then((inventory) => {
        setInventory(inventory);
        setRefreshKey((prev) => prev + 1);
      });
  };

  const handleExport = async () => {
    const obj = {
      jobId: id,
      ids: !!checkedIds?.length ? checkedIds : undefined,
      ...inventoryFilter,
    };

    setLoading(true);
    await inventoryService
      .exportToCsv(obj)
      .then((csvData) => {
        downloadCsvToMachine(csvData, "inventory-export.csv");
      })
      .finally(() => setLoading(false));
  };

  const bulkUpdateInventory = async () => {
    await openaiService.getInventoryWeight(id);
  };
  
  useEffect(() => {
    bulkUpdateInventory();
    fetchJob();
  }, []);

  useEffect(() => {
    if (searchParamReady) {
      fetchInventory(pageNum, inventorySort);
    }
  }, [searchParamReady, inventoryFilter, pageNum, inventorySort]);

  return (
    <>
      <Loading loading={loading} />
      <Flex flexDirection="column">
        <Box
          bg="white"
          mb="24px"
          boxShadow="base"
          px={4}
          py={2}
          borderRadius="md"
          width="100%"
        >
          <Flex justifyContent="space-between">
            <Flex flexDir="row" gap="8px">
              <Flex align="center">
                <Badge
                  colorScheme={
                    job.status === "COMPLETE"
                      ? "green"
                      : job.status === "PENDING" || job.status === "COLLECTION"
                      ? "orange"
                      : "red"
                  }
                >
                  {JobStatus[job.status]}
                </Badge>
              </Flex>
              <Flex hidden={!job.rebateRequired} align="center">
                <Badge colorScheme={"gray"}>
                  {job.rebateRequired ? "Rebate required" : ""}
                </Badge>
              </Flex>
            </Flex>

            <Flex flexDir="row" gap="8px">
              <JobDetailHeader id={id} inventoryCount={inventory?.totalItems} />

              <ButtonCustom neutral onClick={() => setEditModalOpen(true)}>
                Edit job
              </ButtonCustom>
            </Flex>
          </Flex>

          <Flex justifyContent="space-between">
            <Flex
              flexDir="row"
              gap="6px"
              fontSize="3xl"
              mb={2}
              fontWeight="bold"
            >
              <Text>JOB-{job.jobNo}</Text>
            </Flex>
          </Flex>

          {/* card contents */}
          <JobDetailContent
            job={job}
            inventory={inventory.data}
            onUpload={async () => {
              await fetchJob();
              await fetchInventory();
            }}
          />
        </Box>
        <Flex flexDirection="row" gap="6px" justifyContent="flex-end">
          <Flex flexDir="row" gap="6px">
            <ButtonCustom onClick={() => setModalOpen(true)}>
              <AddIcon />
              New Item
            </ButtonCustom>
            <ButtonCustom
              disabled={!inventory?.data?.length}
              secondary
              onClick={handleExport}
            >
              Export to csv
              <DownloadIcon ml="6px" />
            </ButtonCustom>
            <ButtonCustom
              neutral
              secondary
              onClick={() => setUploadSheetState({ open: true, isEdit: false })}
            >
              Upload New
              <UploadFileIcon />
            </ButtonCustom>
            <ButtonCustom
              neutral
              secondary
              onClick={() => setUploadSheetState({ open: true, isEdit: true })}
            >
              Update Existing
              <UploadFileIcon />
            </ButtonCustom>
          </Flex>
        </Flex>
      </Flex>
      <InventoryTable
        key={refreshKey}
        inventory={inventory.data}
        isJobDetail={true}
        onDelete={(id: string) =>
          setInventory({
            data: inventory.data.filter((i) => i.id !== id),
            totalPage: inventory.totalPage,
            totalItems: inventory.totalItems,
          })
        }
        onBulkDelete={async () => await fetchInventory(pageNum)}
        onCheckChange={(data) =>
          setCheckedIds(data.filter((x) => x.isChecked).map((x) => x.id))
        }
        onEdit={async () => await fetchInventory(pageNum)}
        onFilterChange={async (filter: InventoryBulkFilter) => {
          setPageNum(1);
          dispatch(inventoryUpdate(filter));
          await fetchInventory(1);
        }}
        onSort={() => setPageNum(1)}
        footer={
          <TableFooter
            count={inventory.data?.length}
            totalItems={inventory.totalItems}
            totalPage={inventory.totalPage}
            pageNum={pageNum}
            onChange={async (num) => {
              setPageNum(num);
              await fetchInventory(num);
            }}
          />
        }
      />
      <AddInventoryModal
        jobId={id}
        assignedAdminId={job.assignedAdminId}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={fetchInventory}
      />

      <EditJobModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        job={job}
        onSubmit={fetchJob}
      />

      <UploadInventoryModal
        jobId={id}
        isBulkEdit={uploadSheetState.isEdit}
        isOpen={uploadSheetState.open}
        onClose={() => setUploadSheetState({ open: false, isEdit: false })}
        onSubmit={fetchInventory}
      />
    </>
  );
};
