import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { jobService } from "../../../service/job/job.service";
import { companyService } from "../../../service/company/company.service";
import { CompanyModel } from "../../../models/company.model";
import { Badge, Box, Divider, Flex, Text, useToast } from "@chakra-ui/react";
import { AvatarCustom } from "../../../components/form/avatar.custom";
import { JobModel } from "../../../models/job.model";
import { UserModel } from "../../../models/user.model";
import { CreditsModal } from "../../../components/app/company/credits.modal";
import { downloadCsvToMachine } from "../../../util/file.util";
import { CarbonCreditsModal } from "../../../components/app/company/carbon-credits.modal";
import { EmissionModal } from "../../../components/app/company/emission.modal";
import { CardCustom } from "../../../components/shared/CardCustom";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { LanguageOutlined } from "@mui/icons-material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import { CompanyDetailAdditionalInfo } from "../../../components/app/company/detail/additional-detail.component";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import HighQualityOutlinedIcon from "@mui/icons-material/HighQualityOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { CheckboxInput } from "../../../components/form/controls/checkbox.input";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { CompanyEditModal } from "../../../components/modal/company-edit.modal";
import { ButtonCustom } from "../../../components/form/controls/form.button";
import { FlexIconRow } from "../../../components/shared/FlexIconRow";
import AlertDialog from "../../../components/shared/AlertDialog";
import { CompanyDeleteModal } from "../../../components/modal/company-delete.modal";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { companyUserService } from "../../../service/company/company-user.service";
import { FlexRow } from "../../../components/shared/FlexRow";
import InsightsIcon from "@mui/icons-material/Insights";
import { FlexCol } from "../../../components/shared/FlexCol";
import ViewListIcon from '@mui/icons-material/ViewList';
import { StatusTag } from "../../../components/shared/StatusTag";

export const CompanyDetail = ({}) => {
  const profile = useSelector((state: RootState) => state.userProfile);
  const { email, isManager, photoUri } = profile;

  const { id } = useParams();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [creditModalOpen, setCreditModalOpen] = useState(false);
  const [carbonCreditModalOpen, setCarbonCreditModalOpen] = useState(false);
  const [emissionModalOpen, setEmissionModalOpen] = useState(false);

  const [company, setCompany] = useState({} as CompanyModel);
  const [jobs, setJobs] = useState([] as JobModel[]);
  const [users, setUsers] = useState([] as UserModel[]);
  const [branches, setBranches] = useState([] as CompanyModel[]);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteCompanyAlertOpen, setDeleteCompanyAlertOpen] = useState(false);
  const [deleteCompanyConfirmation, setDeleteCompanyConfirmation] =
    useState(false);

  const init = async () => {
    setLoading(true);

    await fetchCompany();
    await fetchJobs();
    await fetchUsers();

    setLoading(false);
  };

  const fetchUsers = async () => {
    await companyUserService.getUsers(id).then((users) => {
      setUsers(users);
    });
  };

  const fetchJobs = async () => {
    await jobService.getList({ companyId: id }).then((jobs) => {
      setJobs(jobs);
    });
  };

  const handleExport = async () => {
    await companyService.exportCreditHistory(id).then((csvData) => {
      downloadCsvToMachine(csvData, "credit-balance-export.csv");
    });
  };

  const fetchCompany = async () => {
    await companyService.get(id).then(async (company) => {
      setCompany(company);
    });
  };

  const fetchBranches = async () => {
    await companyService.getList({ parentCompanyId: id }).then((branches) => {
      setBranches(branches.data);
    });
  };

  const handleRebateCheck = async () => {
    await companyService
      .setRebateRequired(id, !company.rebateRequired)
      .then(() => {
        setCompany({ ...company, rebateRequired: !company.rebateRequired });
        toast(successToast("Rebate required status updated successfully"));
      })
      .catch(() => {
        toast(errorToast("Failed to update rebate required status"));
      });
  };

  useEffect(() => {
    init();
  }, [id]);

  useEffect(() => {
    if (company.isHeadquarters) {
      fetchBranches();
    }
  }, [company.isHeadquarters, id]);

  return (
    <>
      <Flex flexDir="column" gap="16px" px={{ base: "26px", lg: "15%" }}>
        <Flex
          bg="white"
          mt="36px"
          boxShadow="base"
          p={4}
          borderRadius="md"
          pt="50px"
          gap="12px"
          flexDir="column"
          justifyContent="space-between"
          width="100%"
        >
          <AvatarCustom
            styles={{ position: "absolute", top: "80px" }}
            src={company.photoUri}
          />
          <Box p="13px">
            <Flex
              flexDir="row"
              gap="12px"
              fontSize="2xl"
              fontWeight="bold"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex align="center" gap="8px" w="100%">
                {company.name}

                <StatusTag excludeDot type={"info"}>
                  {company.isHeadquarters ? "Headquarters" : "Branch"}
                </StatusTag>
                <StatusTag type={company.deleted ? "error" : "success"}>
                  {company.deleted ? "Inactive" : "Active"}
                </StatusTag>

              </Flex>

              <Flex
                flexDir="row"
                gap="12px"
                fontSize="3xl"
                fontWeight="bold"
                w="100%"
                alignItems="center"
                justifyContent="flex-end"
              >
                <ButtonCustom onClick={() => setEditOpen(true)}>
                  <FlexIconRow>
                    <CreateOutlinedIcon style={{ width: "16px" }} />
                    Edit
                  </FlexIconRow>
                </ButtonCustom>
                {!company.deleted && (
                  <ButtonCustom
                    danger
                    onClick={() => setDeleteCompanyAlertOpen(true)}
                  >
                    Delete
                  </ButtonCustom>
                )}
              </Flex>
            </Flex>
            <Box color="gray" fontWeight="600">

              {/* <Flex mt="6px" flexDir="row" gap="4px">
                <MapOutlinedIcon />
                {company.country}
              </Flex> */}
              <Flex mt="6px" flexDir="row" gap="4px">
                <LocationOnOutlinedIcon />
                {company.addressName ?? "-"}
              </Flex>

              <Flex mt="6px" flexDir="row" gap="4px">
                <LanguageOutlined />
                {company.url ?? "-"}
              </Flex>

              <Flex flexDir="row" alignItems="center" gap="4px">
                <Flex mt="6px" flexDir="row" gap="4px">
                  <Box>ABN</Box>
                  {company.abn ?? "-"}
                </Flex>

                <Box pt="3px">•</Box>

                <Flex mt="6px" flexDir="row" gap="4px">
                  <Box>ACN</Box>
                  {company.acn ?? "-"}
                </Flex>
              </Flex>

              {company.isHeadquarters ? (
                <Flex
                  flexDir="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Flex mt="6px" flexDir="row" gap="4px">
                    <CorporateFareOutlinedIcon />
                    {(branches ?? []).length ?? 0}
                  </Flex>
                  <CheckboxInput
                    name="rebateRequired"
                    title="Rebate required"
                    defaultChecked={company.rebateRequired}
                    onChange={handleRebateCheck}
                  />
                </Flex>
              ) : (
                <Flex mt="6px" flexDir="row" gap="4px">
                  <HighQualityOutlinedIcon />
                  <Box
                    cursor="pointer"
                    textDecoration="underline"
                    onClick={() =>
                      window.open(
                        `/app/company/${company.parentCompanyId}`,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    View HQ
                  </Box>
                </Flex>
              )}

              <FlexCol>
                <Box fontWeight="600" color="black" mt="16px">
                  Inventory Impact
                </Box>

                <FlexRow>
                  <ViewListIcon />
                  <FlexRow>
                    <Text>Total:</Text>
                    <Text>
                      {company?.inventoryImpact?.totalInventory ?? 0} items
                    </Text>
                  </FlexRow>
                </FlexRow>
                <FlexRow>
                  <InsightsIcon />
                  <FlexRow>
                    <Text>Reuse:</Text>
                    <Text>
                      {company?.inventoryImpact?.totalReuse ?? 0} items
                    </Text>
                  </FlexRow>
                  ≈
                  <FlexRow>
                    <Text>
                      {company?.inventoryImpact?.reuseWeight ?? 0}
                      kg
                    </Text>
                  </FlexRow>
                </FlexRow>
                <FlexRow>
                  <InsightsIcon />
                  <FlexRow>
                    <Text>Recycling:</Text>
                    <Text>
                      {company?.inventoryImpact?.totalRecycle ?? 0} items
                    </Text>
                  </FlexRow>
                  ≈
                  <FlexRow>
                    <Text>
                      {company?.inventoryImpact?.recycleWeight ?? 0}
                      kg
                    </Text>
                  </FlexRow>
                </FlexRow>
                
              </FlexCol>
            </Box>
          </Box>

          <Divider />

          <Flex flexDir="row" gap="16px" h="100%">
            <DashboardCard
              label={<Text>Credits</Text>}
              value={`$${company.credits}`}
              actions={
                <Flex flexDir="row" gap="6px">
                  <CreateOutlinedIcon
                    cursor="pointer"
                    onClick={() => setCreditModalOpen(true)}
                  />
                  <GetAppOutlinedIcon cursor="pointer" onClick={handleExport} />
                </Flex>
              }
            />

            <DashboardCard
              label={<Text>CO2 Emissions avoided (month)</Text>}
              value={company.monthEmission}
              actions={
                <CreateOutlinedIcon
                  cursor="pointer"
                  onClick={() => setEmissionModalOpen(true)}
                />
              }
            />
          </Flex>
        </Flex>

        <Flex h="500px" flexDir="row" gap="16px">
          <CompanyDetailAdditionalInfo
            companyId={id}
            jobs={jobs}
            users={users}
            branches={branches}
            onDeleteJob={fetchJobs}
            onSuccess={fetchUsers}
          />
        </Flex>
      </Flex>

      <CreditsModal
        companyId={id}
        balance={company.credits}
        isOpen={creditModalOpen}
        onClose={() => setCreditModalOpen(false)}
        onSubmit={(credit: number) =>
          setCompany({ ...company, credits: credit })
        }
      />

      <CarbonCreditsModal
        companyId={id}
        balance={company.carbonCredits}
        isOpen={carbonCreditModalOpen}
        onClose={() => setCarbonCreditModalOpen(false)}
        onSubmit={(carbonCredits: number) =>
          setCompany({ ...company, carbonCredits })
        }
      />

      <EmissionModal
        companyId={id}
        balance={company.monthEmission}
        isOpen={emissionModalOpen}
        onClose={() => setEmissionModalOpen(false)}
        onSubmit={init}
      />

      <CompanyEditModal
        isOpen={editOpen}
        onClose={() => setEditOpen(false)}
        onSubmit={fetchCompany}
        company={company}
      />

      <AlertDialog
        isOpen={deleteCompanyAlertOpen}
        onClose={() => setDeleteCompanyAlertOpen(false)}
        title="Danger ahead"
        colorScheme="red"
        confirmText="Delete"
        onConfirm={() => {
          setDeleteCompanyAlertOpen(false);
          setDeleteCompanyConfirmation(true);
        }}
      >
        You can't undo this action. <br /> All the company's jobs, users,
        branches, and inventory will be deleted.
      </AlertDialog>

      <CompanyDeleteModal
        isOpen={deleteCompanyConfirmation}
        onClose={() => setDeleteCompanyConfirmation(false)}
        onSubmit={init}
        company={company}
      />
    </>
  );
};

const CompantyDetailCard = ({ label, value }) => {
  return (
    <CardCustom shadow="none" alignItems="center">
      <Box fontSize="14px" fontWeight="700" color="gray">
        {label}
      </Box>
      {value}
    </CardCustom>
  );
};

const DashboardCard = ({ label, value, actions }) => {
  return (
    <CardCustom shadow="none" gap="12px" alignItems="center">
      <Box fontSize="14px" fontWeight="700" color="gray">
        {label}
      </Box>
      <Box fontSize="26px" fontWeight="600" color="brand.green">
        {value ?? "0"}
      </Box>
      {actions}
    </CardCustom>
  );
};
