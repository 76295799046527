import { Image } from "@chakra-ui/react";

export const IconCustom = (props: { fileName; onClick?; style?, w?, h? }) => {
  return (
    <Image
      src={`/assets/${props.fileName}.svg`}
      w={props.w || "24px"}
      h={props.h || "24px"}
      cursor={"pointer"}
      onClick={props.onClick}
      {...props.style}
    />
  );
};
